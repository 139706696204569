import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { lamour } from '../../../components/cards/import';

const Lamouretsesdetours = () => {
    const title = 'L’Amour et ses détours';
    const author = 'Agathe Amzallag';
    const newLocal = `
    <div>
    <h6>Centrée sur la vie amoureuse et tumultueuse de trois
    compositeurs contemporains, Donizetti, Bellini, Verdi, conférence donnée
    pour l’Association des Clubs France-Andorre-Maroc-Algérie-Tunisie
    (International Inner Wheel).</h6>
    <p>Du drame conjugal de Donizetti au choc amoureux de Bellini,
    de la tragédie familiale de Verdi à sa rencontre avec la « diva
    assoluta », Giuseppina Strepoli, les fougues de l’amour et les flots de
    passion amènent ces trois artistes à trouver dans l’opéra un mode
    d’expression musicale qui fera évoluer de façon exceptionnelle l’opéra
    romantique italien.</p>
    
    </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={lamour} />
        <Article title={title} author={author} content={content} />
      </div>
    );
  };
export default Lamouretsesdetours