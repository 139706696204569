import React, { useState } from 'react';

import './header.css';

const Header = () => {
  const [title] = useState("Agathe Amzallag");
  const [description] = useState("Musicologue");
  // eslint-disable-next-line no-useless-escape
  const [bio]= useState("Découvrez la vie des compositeurs, leur relation avec le pouvoir, \nle rôle de la musique dans la société,\n l'évolution des instruments à travers mes conférences, articles, présentations et collaborations diverses.");
  
  return (
    <div className="gpt3__header " id="home">
      <div className="gpt3__header-content">
        <h1 style={{ fontFamily: "'Tangerine', serif", color: '#2B50AA' }}>
          {title}
          </h1>
          <h2 className='musicologue'>{description}</h2>
        <div classname="paragraphe">
          <p >{bio}</p>
         
        </div>
      </div>
    </div>
  );
};

export default Header;