import React from 'react';
import { Link } from 'react-router-dom';
import './Cards.css';

function CardItem(props) {
  const handleImageLoad = (event) => {
    event.target.removeAttribute('role');
  };
  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path} onClick={handleClick}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
          <img
            className='cards__item__img'
            alt=''
            src={props.src}
            width='100%' // Adjust the width as needed
            height='100%' // Adjust the height as needed
            onLoad={handleImageLoad}
          />
            
          </figure>
          <div className='cards__item__text'>{props.text}</div> {/* Move text outside the figure */}
        </Link>
      </li>
    </>
  );
}

export default CardItem;

