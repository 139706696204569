import React from 'react';
import Article from '../../../components/Article/article';
import ArticleHeader from '../../../components/Article/ArticleHeader';
import { boris } from '../../../components/cards/import';

const BorisGodunov = () => {
  const title = 'Boris Godounov   <br/> Pouvoir, crime et châtiment';
  const author = 'Agathe Amzallag';
  const content = `
    <div>
      <h6>Paris-Février 2024
      <br />
      A l’occasion de la Première de l’opéra, Boris Godounov, au Théâtre des
      Champs Élysées, Conférence pour le Cercle des Mécènes sur l’histoire de
      l’œuvre et celle du compositeur.</h6>
      <p>Inspiré par la tragédie de Pouchkine centrée sur le règne et la chute du Tzar
      Boris Godounov, Moussorgski entreprend en 1869 de créer une fiction en vers
      et en prose destinée à faire réfléchir sur la nature du pouvoir en Russie, sur le
      rôle des « boyards » et sur la place assignée au peuple toujours victime de jeux
      politiques meurtriers.</p>
      <p>Ainsi naîtra Boris Godounov, opéra âpre, violent accompagné d’une
      orchestration sauvage et flamboyante où le portrait imposant, terrifiant et
      pitoyable d’un tzar criminel dévoré par une puissance autodestructrice côtoie
      celui de la révolte du peuple russe.</p>
      <p>Seul opéra entièrement achevé et représenté du vivant de Moussorgski, Boris
      Godounov tire les fils d’un drame national et annonce les bouleversements à
      venir de la société russe.</p> 
    </div>
  `;

  const additionalContent = `
  <div >
  <p>Enregistrée à l’issue de la conférence donnée au Théâtre des
  Champs Élysées, Vidéo sur l’histoire de l’œuvre et celle du
  compositeur :</p>
  <div class="iframe-container">
    <iframe src="https://www.youtube.com/embed/USC8YIkOPJs" 
      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen></iframe>
  </div>
</div>

  `;

  return (
    <div>
      <ArticleHeader title={<div dangerouslySetInnerHTML={{ __html: title }} />} image={boris} />
      <Article title={title} author={author} content={content} />
      <Article content={additionalContent} />
    </div>
  );
};

export default BorisGodunov;
