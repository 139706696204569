import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { ballets, russe,} from '../../../components/cards/import';

const LesBalletsRusses = () => {
    const title = 'Les Ballets Russes embrasent Paris';
    const author = 'Agathe Amzallag';
    const image = russe;
    const newLocal = `
    <div>

     <h6> Pour le Magazine Historia(Avril 2011)</h6>
      
     <p>Avec l'arrivée en 1909 de cette troupe unique, chorégraphies originales, musiques inédites et décors avant-gardistes s'emparent de la scène en évoquant une terre de légende. Une aventure exceptionnelle.</p>
     <p>«Le scandale provoqué par ces ballets a secoué le formalisme de la scène et ouvert un nouveau champ musical. »  Agathe Amzallag</p>
     <p>Le public est conquis ! Les femmes en particulier, séduites par les costumes de Léon Bakst, de Natalia Gontcharova et Mikhaïl Larionov, ne rêvent plus que de formes géométriques, de couleurs éclatantes, d'étoffes luxuriantes, de plumes d'autruche et de bijoux tout en aspirant à une plus grande liberté du corps. Un vent nouveau se met à souffler sur la mode, ouvrant aux grands couturiers de l'époque, Edward Molyneux, Mario Fortuny, Jeanne Paquin, Paul Poiret, Jeanne Lanvin et Coco Chanel, une source inépuisable d'inspiration.</p>
     <p>Turbans lamés ornés de plumes dans le style de L'Oiseau de feu, robes incrustées de pierres précieuses imitées de Shéhérazade, fourrures opulentes empruntées au Prince Igor... L'exotisme bat son plein ! Face à cet engouement, Paul Poiret lance le « pantalon de harem », les robes garnies de petits cerceaux, les « jupes sultanes », les tuniques « abat-jour » et les capes frangées qui deviennent l'apanage incontournable d'une clientèle aisée. Fervente adepte des Ballets russes. Jeanne Lanvin donne même à plusieurs de ses modèles le nom de la célèbre danseuse étoile, Tamara Karsavina.</p>
     <p>Chanel fait venir une cinquantaine de brodeuses slaves. Mais, de tous les couturiers de l'époque, Chanel est certainement celle qui s'investit le plus dans l'imaginaire slave. Très proche de Jean Cocteau, d'Igor Stravinski et de Serge Lifar, elle est intime avec Diaghilev dont elle dit qu'il est « l'ami le plus charmant avec son air de chat fourré gourmand, son rire à grosses lèvres ouvertes, ses joues pendantes, l'oeil bon et narquois sous le monocle dont la ganse noire se promene au vent... ».</p>
     <p>Pour lui, elle crée les costumes de quatre ballets dont Le Train bleu en 1924 mais, pour sa clientèle, elle invente la roubachka, la longue blouse ceinturée des moujiks, qui devint aussitôt l'uniforme de la Parisienne. S'adonnant sans réserve à sa passion tant pour les Ballets russes que pour le grand duc Dimitri, cousin du tsar Nicolas II, Chanel multiplie les défilés de fourrures présentés par des mannequins russes, transpose en accessoire joyaux impériaux de la cour de Russie et, dans sa maison de couture, la langue de Tolstoi prend le pas sur celle de Voltaire! Rehaussant ses création de dessins multicolores issus de l'art populaire russe et ornés de perle de verre ou de paillettes, elle engage même une cinquantaine de brodeuses venues de Russie qu'elle place sous la direction de Maria Pavlovna, la soeurdu grand-duc. C'est d'ailleurs dans cet atelier, la Maison Kitmir, que seront réalisées les broderies de Jean Patou, directement inspirées du folkore russe. </p>
      <img src="${image}" alt="Image" />
      <label><span style="font-style: italic; text-decoration: underline; text-underline-offset: 4px; text-decoration-skip-ink: auto; font-weight: bold;" >Le Charme Oriental</span></label>
      <label>La première saison des Ballets russes, crées en 1907 par Diaghilev avec les meilleurs éléments du théâtre Mariinsky de Saint-Pétersbourg, eut lieu au Châtelet au printemps 1909. Chaque année, à cette période, la compagnie revint à Paris sur d'autres scènes.</label>
     <p>Telle une tornade, la mode dictée par les Ballets russes déferle sur la capitale modelant coiffures, accessoires et bijoux pour gagner bientôt les parfums. Il faut dire qu'en 1910, après la première représentation de Shéhérazade, le Tout Paris, ébloui par les décors, les costumes et la musique de Rimski Korsakov, ne jure que par les nuits d'Arabie. Paul Poiret crée alors deux parfums d'inspiration orientale Le Minaret et Aladin, et ils font rage!</p>
     <p>Fort de ce succès, dix ans plus tard, il lance Nuit de Chine et Sakya Mouni, ce dernier étant une réminiscence des dessins de Matisse pour Le Chant du rossignol, un poème symphonique d'Igor Stravinski transposé en ballet par Diaghilev. Et bientôt, toujours inspiré par le compositeur, Arlequinade s'impose à son tour.</p>
     <p>Malgré la mort de Diaghilev, l'impact des ballets perdure mais face aux coûts faramineux de ces productions, Paul Poiret, ruiné, doit renoncer à son aventure olfactive. Au même moment, la mort de Diaghilev sonne le glas des Ballets russes. Loin de disparaître, leur impact va au contraire se renforcer. Témoin en 1976, la collection Opéra-Bal lets russes d'Yves Saint Laurent toute de velours et de brocards dont il dira «Je ne sais pas si c'est ma meilleure collection, mais c'est la plus belle! »</p>
     <p>Les défilés de Marc Jacobs pour Louis Vuitton avec hautes chapkas de fourrure et valises s'emboîtant les unes dans les autres soulignent le fameux exotisme des années 1920.</p>
     <p>Quant à Karl Lagerfeld, il se réfère souvent aux acteurs de l'avant-garde russe, de même John Galliano qui leur rend un vibrant hommage dans sa collection pour Dior en 2002.</p>
    </div>`;
    
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={ballets} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default LesBalletsRusses