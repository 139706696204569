import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { Beethoven, LeGrandBouc, Francisco, les_vieilles, lanevada } from '../../../components/cards/import';

const DeuxGeniesArticle = () => {
    const title = 'Goya - Beethoven<br>Deux génies à l\' unisson';
    const author = 'Agathe Amzallag';
    const image = LeGrandBouc;
    const newLocal = `
    <div>
    
    <h6>Pour le Magazine Art Passions (N°37)</h6>
  
    <p>Ces contemporains ne se rencontreront jamais, mais que de similitudes dans leur trajectoire, leurs idéaux, leurs épreuves... le parallélisme est frappant ! Aperçu avec, pour toile fond, l'histoire européenne.</p>
    <p>A l'origine, tout les séparait ! Leur pays, leurs racines... pourtant, ces artistes-nés suivront le même chemin. Leur but? Dépasser les contraintes du classicisme. Affirmer leur expression romantique, le caractère dramatique de leurs œuvres et surtout, travailler pour la postérité.</p>
    <p>Goya naît en 1746 à Fuendetodos, Beethoven en 1770 à Bonn. Adeptes des «Lumières», ils se passionnent pour la Révolution française et pour la liberté, thème qui nourrira leur inspiration. Chez les deux artistes, renommés, mais victimes d'une surdité incurable, la « nuit auditive» se métamorphose en une créativité redoublée, alors qu'ils se font les commentateurs des conflits politiques de leur pays.</p>
    <p>Leurs références? La tradition, Le Titien, Vélasquez, Bach. Haendel... mais la nouveauté aussi, car avides de découverte, ils exploreront tous les genres: de l'aquatinte aux estampes, des gravures aux dessins pour l'un; des sonates aux symphonies, mais aussi des quatuors aux concertos, opéras ou messes, pour l'autre. De plus - fait intéressant - ils s'ouvrent à la création dès l'enfance.</p>
    <p>En effet, fils d'un maître doreur, Goya se forme à onze ans dans l'atelier de son père et y exécute ses premières œuvres, alors que Beethoven, petit-fils et fils de musicien, publie au même âge Neuf Variations pour clavier sur une marche de Dressler.</p>
    <p>À ce stade, ce sont des artistes «en herbe» certes, mais leur avenir est prometteur!</p>
    <p>À 25 ans, Goya épouse, à Madrid, la sœur de Francisco Bayeu, l'un des peintres les plus en vue de l'entourage royal et bientôt les commandes affluent: cartons de tapisseries dans la ligne des fêtes galantes introduites par Watteau au début du XVIII* siècle, portraits aristocratiques, peintures religieuses...</p>
    <p>L'élite éclairée de l'Espagne s'ouvre à lui. Il entre à l'Académie San Fernando, devient «Peintre du roi» et obtient la permission de graver les tableaux de Vélasquez, explorant pour la première fois une technique récente qui imite le lavis: l'aquatinte.</p>
    <p>Passionné par ce travail, il dira plus tard: «J'ai eu trois maîtres: Rembrandt, Vélasquez et la Nature.»</p>
    <p>De son côté, en 1792, Beethoven - alors âgé de 23 ans - aborde la vie avec confiance! En effet, il est protégé par l'Électeur Maximilien Franz, puis par le comte Ferdinand von Waldstein qui l'emmène se former à Vienne auprès de Haydn. La ses talents de pianiste et d'improvisateur sont vite reconnus, mais déjà atteint par la surdité, il note, indomptable, dans son carnet intime: «Courage! Malgré toutes les défaillances du corps, mon génie triomphera...»</p>
    <p>En 1796 - triste similitude - Goya, victime d'une attaque cérébrale, se découvre complètement sourd, mais « pour occuper son imagination mortifiée par la considération de ses maux», il peint une suite de tableaux sur cuivre et s'adonne à la pratique d'un genre nouveau, le dessin.</p>
    <p>Un an plus tard, c'est le début des Caprices, une série de gravures dans lesquelles il cherche à atteindre la vérité cachée des êtres qu'il représente. C'est une satire cinglante de la société espagnole de l'époque, le fantastique se mêlant à la réalité. André Malraux dira, à leur propos: «Jérôme Bosch introduit les hommes dans son univers infernal, Goya, lui, a introduit l'infernal dans l'univers humain.»</p>
    <img src="${image}" alt="LeGrandBouc" />
    <label>Francisco Goya Le Grand Bouc, 1819-1823 Huile sur toile, 438 x 140 cm Musée du Prado, Madrid</label>
    <p>Pendant ce temps, en marche vers le pouvoir, Napoléon Bonaparte, à la tête de l'armée d'Italie, écrase les troupes autrichiennes. Face à cette avancée conquérante, Haydn compose alors l'Hymne autrichien et dans la foulée, après la bataille d'Arcole, Beethoven crée à son tour, pour une levée de volontaires, un Chant d'adieu aux citoyens de Vienne, suivi d'un chœur patriotique. Avec ces prises de position, sa popularité redouble! </p>
    <p>II publie sa Première Symphonie qui scandalise par son allure de «musique militaire», mais loin de s'en émouvoir, il continue sur sa lancée et en 1804, considérant le Général Bonaparte comme «le dieu de la victoire» grâce auquel sera fondée une République héroïque, il lui dédie sa Troisième Sym-phonie. Mais lorsqu'il apprend que Napoléon s'est couronné empereur, indigné, il déchire la dédicace en écrivant ce titre vengeur: Symphonie héroïque... pour célébrer le souvenir d'un grand homme.</p>
    <p>Vienne passe à son tour aux mains de Napoléon dont le frère, Joseph Bonaparte, trois ans plus tard, s'assied sur le trône d'Espagne. Face à cette furia militaire et à l'occupation par la Grande Armée de leur pays respectif, Goya comme Beethoven composent des œuvres à portée historique.</p>
    <p>Ce seront, tout d'abord, Les Désastres de la Guerre, une série d'eaux-fortes d'une extrême violence qui révèleront au monde les scènes de pillage et de pendaison, les fusillades, les mutilations, les charniers... Ces gravures seront encore une source tragique d'inspiration pour de nombreux artistes du XX* siècle, de l'Allemand Otto Dix, qui dénoncera le carnage de la Première Guerre mondiale, à Picasso qui protestera contre les bombardements de l'aviation allemande sur Guernica. Se faisant l'écho du «cri désespéré» de Goya, Beethoven compose des œuvres épiques, passionnées et tumultueuses, tels L'ouverture de Coriolan, le Quatrième quatuor, op.18, ou encore la Sonate Appassionata dont Bismarck disait: «Si je l'entendais souvent, je serais toujours vaillant. »</p>
    <p>En 1812, c'est la retraite de Russie et cette même année, le Général anglais Wellington chasse les Français d'Espagne. Pour honorer le héros, Goya exécute alors deux tableaux à sa gloire, tandis qu'à Vienne, Beethoven fête l'événement en créant une œuvre étrange à la gloire des armées britanniques: la Bataille de Vitoria ou La Victoire de Wellington.</p>
    <p>De part et d'autre l'espoir renaît ! Pour peu de temps hélas, car morcelée, enchaînée par la police de Metternich et soumise à la politique de la Sainte-Alliance, l'Allemagne connaît une période nettement réactionnaire.</p>
    <p>Plus solitaire que jamais, ne communiquant avec le monde extérieur que par écrit, Beethoven est indigné! Mais au printemps 1818, le «Grand Mo-gol», ainsi que le surnommait Haydn avec malice, se remet au travail et, dans une grande exaltation spirituelle, attaque la composition de sa Missa solemnis, avant de s'atteler, à la fin de 1822, à la Neuvième Symphonie.</p>
    <img src="${Francisco}" alt="Francisco" />
    <label>Francisco Goya, Le Sommeil de la raison produit des monstres (Caprice 43), 1797-1798, Eau-forte et aquatinte, 21,6 x 15,2 cm</label>
    <img src="${les_vieilles}" alt="lesvieilles" />
    <label>Francisco Goya Les vieilles (Le Temps), 1810-1812 Huile sur toile, 181 x 125 cm Palais des Beaux-Arts, Lille</label>
    
    <img src="${lanevada}" alt="lanevada" />
    <label>Francisco Goya L'Hiver ou la Tempête de neige, 1786-1787 Huile sur toile, 293 x 275 cm Musée du Prado, Madrid</label></p>
    <p>Après dix ans de ce travail extraordinaire, durant lesquelles le peintre composera les Disparates, œuvres pétries d'extravagance, d'incohérence et de folie, Goya décide, en 1824, de s'installer à Bordeaux - alors port de négoce espagnol - pour échapper au climat d'inquisition qui règne en Espagne. Toujours entreprenant et jeune d'esprit malgré son âge - ne dira-t-il pas un jour qu'il pourrait vivre comme Le Titien jusqu'à 99 ans? - mais c'était compter sans Cronos qui veillait dans l'ombre...</p>
    <p>Au printemps 1827, le premier des deux artistes, Beethoven, meurt à Vienne. Selon le témoignage de son entourage, le regard farouche et le poing brandi vers le ciel, il eut dans ses derniers instants, un geste de défi, prêt à livrer un nouveau com-bat. Un an plus tard, en avril 1828, c'est au tour de Goya de s'éteindre. Peu auparavant, dans l'un de ses derniers dessins, il avait campé un vieillard aux pieds nus assis sur une balançoire qui, dans un grand éclat de rire, s'envolait dans les airs... Était-ce lui, Goya, qui, après avoir accompli son destin, prenait congé du monde et de nous dans la joie et la légèreté? La question reste posée..</p>
    </div>`;
    const content = newLocal;
  
    return (
      <div>
         <ArticleHeader title={<div dangerouslySetInnerHTML={{ __html: title }} />} image={Beethoven} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default DeuxGeniesArticle