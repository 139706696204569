import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navbar, } from './components';
import { Home, Conference, Ecrits, Presentations, About, CosiArticle, PaysDenhautArticle, DeuxGeniesArticle, LesBalletsRusses, LesSalonsMusique,Fedora, Lohengrin, TheHours, Gershwin, BeethovenVerdi, Falstaff, Dhiaghilev, BorisGodunov} from './containers'
import './App.css';
import Tosca from './containers/conference/Article/Tosca';
import Lamouretsesdetours from './containers/conference/Article/Lamouretsesdetours';


const App = () => {
  

  return (
    <div className='App'>
      <Navbar />
      <div className='gradient__bg'>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route path="/conférences" element={<Conference/>} />
            <Route path="/écrits" element={<Ecrits/>} />
            <Route path="/présentation_d_opéra" element={<Presentations/>} />
            <Route path="/à_propos" element={<About/>} />
            <Route path='/cosi' element={<CosiArticle />} />
            <Route path='/paysdenhaut' element={<PaysDenhautArticle />} />
            <Route path='/deuxgenies' element={<DeuxGeniesArticle />} />
            <Route path='/lesballets' element={<LesBalletsRusses />} />
            <Route path='/lessalons' element={<LesSalonsMusique />} />
            <Route path='/fedora' element={<Fedora />} />
            <Route path='/lohengrin' element={<Lohengrin />} />
            <Route path='/thehours' element={<TheHours />} />
            <Route path='/gershwin' element={<Gershwin />} />
            <Route path='/beethovenverdi' element={<BeethovenVerdi />} />
            <Route path='/tosca' element={<Tosca />} />
            <Route path='/falstaff' element={<Falstaff />} />
            <Route path='/lamour' element={<Lamouretsesdetours />} />
            <Route path='/diaghi' element={<Dhiaghilev />} />
            <Route path='/boris' element={<BorisGodunov />} />
        </Routes>  
        </div> 
        
       
    </div>
  );
};

export default App;
