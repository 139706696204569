import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { fedora } from '../../../components/cards/import';

const Fedora = () => {
    const title = 'Fedora';
    const author = 'Agathe Amzallag';
    const newLocal = `
    <div>
    <h6>Texte adressé par mail et mis à disposition des spectateurs dans les cinémas
    avant la diffusion en direct des opéras du Metropolitan Opera de New York.</h6>
    <p>Fils de pharmacien né en Italie, à quatorze ans, Umberto Giordano entre contre la volonté de ses parents au Conservatoire de Naples, une institution considérée comme le vivier privilégié du vérisme italien. Issu d'un mouvement littéraire, le vérisme avait en effet gagné le domaine musical avec des compositeurs tels Ruggero Leoncavallo et Francesco Cilea qui racontaient des histoires émouvantes à partir de sujets issus de la vie quotidienne. Derniers défenseurs de la tradition du bel canto, dans les œuvres de ces musiciens, le discours était continu, les contrastes violents, les mélodies claires et simples et s'ils refusaient la veine comique, ils imposaient néanmoins des finals grandiloquents avec quelques grands airs bien chantés.</p>
    <p>Bien qu'attiré par la scène lyrique, dans un premier temps, Umberto Giordano compose de la musique de chambre, des mélodies et des pièces pour piano mais en 1892, il crée Mala vita, un opéra qui contribue à la naissance de la mode vériste. Quatre ans plus tard, avec Andrea Chenier, sa deuxième œuvre d'importance -et son plus grand succès- il connaît gloire et prospérité. Mais bientôt, en continuant sur sa lancée, il obtient les droits de la célèbre pièce de théâtre de Victorien Sardou (l'auteur de Tosca), Fedora, jouée par l'illustre Sarah Bernhardt. Adaptée par le librettiste Arturo Colautti et mise en musique par U. Giordano, cette sombre histoire de vendetta russe devint alors une sorte de roman policier lyrique qui, en trois actes, tint le public en haleine.</p>
    <p>Créée en Novembre 1898 à la Scala de Milan, dès la soirée d'ouverture, Fedora remporte un immense succès ! Reconnue pour sa valeur musicale ainsi que pour le talent d'un jeune ténor encore inconnu, Enrico Caruso, l'œuvre est rapidement programmée par Gustav Mahler au Staatsoper de Vienne avant d'être présentée à Paris où elle est saluée par Massenet et Saint-Saëns. Et pour comble de succès, en 1906, Fedora est donnée pour la première fois au Metropolitan Opera de New-York avec toujours dans le rôle-titre, le grand Caruso, devenu une célébrité internationale.</p>
    <p>Quant à l'histoire aussi intense que dramatique, elle se situe dans les années 1870 et raconte qu'à la veille de ses noces avec la princesse Fedora, le prince russe, Vladimir Yariskine, est assassiné. Jurant de venger son fiancé, Fedora cherche alors de tous côtés à retrouver le coupable mais en arrivant à Paris, elle fait la connaissance d'un peintre russe, le Comte Loris Ipanoff, dont elle tombe amoureuse.</p>
    <p>Convaincue au fil de leur relation que Loris est l'assassin qu'elle recherche, elle envoie à la police russe une lettre de dénonciation. Une fois arrivée, celle-ci provoque aussitôt l'arrestation du frère de Loris Ipanoff qui est considéré comme complice du crime. Il est donc emprisonné mais une inondation de la Neva envahit les cellules de la prison et le jeune homme est noyé ! Désespérée par la perte de ses fils, la mère des deux garçons meurt de chagrin et bientôt -fait ahurissant- Fedora découvre que son défunt fiancé n'est pas celui qu'elle croyait. En effet, aventurier sans scrupules, il était l'amant de la femme du Comte Loris Ipanoff qui, atteint dans son honneur, avait donc provoqué un duel. Et c'est ainsi que le prince Vladimir Yariskine avait été tué alors que Loris, lui, n'avait été que blessé. Trop tardivement instruite de la vérité et ne pouvant supporter les terribles conséquences de son acte qui avait entrainé la mort du frère et de la mère de Loris, Fedora désespérée, prend le poison contenu dans une croix que lui avait offerte son fiancé à la veille de ses noces et décide de se tuer. Mais l'amour étant le plus fort, quand elle meurt dans les bras de son amant, celui-ci lui pardonne son erreur..</p>
    <p>Pour interpréter ce drame placé sous le signe de la vengeance et de la tragédie, seuls des chanteurs d'exception dépassant les frontières du seul « beau chant », peuvent se mesurer à la violence et aux sentiments exacerbés des personnages. Ce sera d'ailleurs le cas de Maria Callas et de Renata Tebaldi dont les interprétations magistrales, donneront dans les années 50 ses lettres de noblesse au rôle de Fedora. Quant à Umberto Giordano, après l'immense succès remporté par Andrea Chénier et Fedora, en bon épicurien, il vivra une maturité et une vieillesse confortables se contentant d'exploiter les retombées de son talent et jouissant paisiblement du succès international de ses œuvres dont les droits feront sa fortune.</p>
    </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={fedora} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default Fedora