import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { TheHour } from '../../../components/cards/import';

const TheHours = () => {
    const title = 'The Hours';
    const author = 'Agathe Amzallag';
    const newLocal = `
    <div>
    <h6>Texte adressé par mail et mis à disposition des spectateurs dans les cinémas
    avant la diffusion en direct des opéras du Metropolitan Opera de New York.</h6>
   
    <p>Né dans le Missouri en 1972, Kevin Puts est souvent décrit comme l'un des compositeurs américains les plus importants de sa génération. Après des études universitaires de piano et de composition, à seulement 24 ans il est nommé simultanément compositeur-en-résidence au
    « Young Concert Artists » de New York et à la « California Symphony ».</p>
    <p>Aujourd'hui, M. Kevin Puts n'a pas encore 45 ans, mais son travail a déjà été récompensé par plusieurs prix prestigieux dont le Prix Pulitzer de musique pour son opéra Silent night.</p>
    <p>Parallèlement, ses créations sont jouées par des ensembles de renommée internationale et par des artistes prestigieux tel le violoncelliste Yo-Yo Ma.</p>
    <p>Créateur d'une cinquantaine d'œuvres, celles-ci se distinguent par leur diversité stylistique, formelle et instrumentale. En effet, si le style musical de Kevin Puts s'inscrit dans une tradition « néo-romantique » en s'inspirant de la musique du XIXe siècle dont celle de Beethoven, il expérimente aussi d'autres expressions musicales comme le minimalisme ou la musique populaire.</p>
    <p>Quoiqu'il en soit, en 1999, sa Première symphonie marquera un tournant dans sa carrière car bientôt ses œuvres traiteront de problématiques contemporaines telle la violence dans les écoles, la guerre en Irak et en Afghanistan ou encore le réchauffement climatique. Autres exemples frappants, sa Deuxième Symphonie, illustration musicale des événements du 11 Septembre 2002 et son opéra, Silent night, qui traite de la fraternité et de l'absurdité de la guerre. D'ailleurs, dans une récente entrevue, Kevin Puts précise : « J'essaye toujours dans mes œuvres de dire quelque chose qui aura un impact sur le public [...] Je ne fais pas de compromis par rapport à mon style mais les décisions que je prends dès les premières mesures se résument dans une question : Comment donner envie aux gens de continuer à écouter ?».</p>
    <p>Un défi auquel répond The Hours, un opéra en deux actes inspiré par le roman de M. Cunningham et dont le livret de Greg Pierce met en scène vingt-quatre heures dans la vie de trois femmes appartenant à des générations différentes. Se déroulent alors à trois époques différentes trois destins qui se confondent, alors même que chacune de ces trois femmes lutte contre ses démons intérieurs et s'interroge sur sa place dans la société.</p>
    <p>L'histoire commence tout d'abord dans la banlieue de Londres dans les années 1920, lorsque Virginia Woolf, luttant contre la folie et son penchant suicidaire, entame l'écriture de son nouveau roman, Mrs Dalloway. Trente ans plus tard, dans l'Amérique de l'après-guerre, Laura Brown lit cet ouvrage à Los Angeles et profondément bouleversée, elle songe à changer radicalement de vie alors même qu'elle est en train d'organiser une réception pour l'anniversaire de son mari. Et cinquante ans plus tard, à l'aube du XXlème siècle, Clarissa Vaughan, troisième personnage et version moderne de Mrs Dalloway, prépare à New York une réception en l'honneur de son ami poète et ancien amant, Richard, qui, dévoré par le Sida, n'est autre que le fils de Laura.</p>
    <p>Dans ce jeu de miroir dont le fil directeur est Mrs Dalloway - roman phare de Virginia Woolf- on suit donc l'évolution de ces trois femmes avec leurs désillusions, leurs espérances, leurs petits plaisirs, leurs vrais malheurs, chacune d'elles étant reliée à l'autre mais dans un temps différent.</p>
    <p>Sur le plan musical, Kevin Puts précise : Je suis conscient du défi que représente cet opéra qui doit se distinguer nettement du roman et du film qui en a été tiré. Mais la musique a sa personnalité propre et ces personnages sont fascinants! Mon premier opéra concernait essentiellement des hommes mais maintenant. J'aime composer pour des voix de femmes avec des mélodies qui leurs correspondent.</p>
    <p>L'orchestre est également très important pour moi car il a la charge de révéler l'histoire; son impact est égal à celui des solistes et des chœurs, si bien que cet opéra pourra aussi être perçu comme une œuvre de concert.</p>
    <p>The Hours ? Dans la vie de trois femmes, une journée de méditation sur le temps, l'amour et la mort.</p>
   
    </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={TheHour} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default TheHours