import React from 'react'
import { Header } from '../../components'
import { About, Ecrits } from '../../containers'
import { Conference } from '../../containers'
import { Presentations } from '../../containers'
import {ImageCarousel} from '../../components'

const Home = () => {
  return (
    <>
    <Header/>
    <ImageCarousel/>
    <Ecrits />
    <Presentations/>
    <Conference/>
    <About/>
    </>
  )
}

export default Home