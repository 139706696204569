import React from 'react';
import '../../components/cards/Cards.css';
import CardItem from '../../components/cards/CardItem';
import { falstaff, fedora, lohengrin, TheHour, Cosi } from '../../components/cards/import';
import './Presentations.css';

function Presentations() {
  return (
    <div className='cards presentations'>
      <h1>
         {/* <span>∼</span> */}Présentations d'opéras  {/* <span>∼</span>*/}
      </h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <ul className='cards__items1'>
              <CardItem
                src={`${lohengrin}?w=576 576w, ${lohengrin}?w=1000 1000w`}
                sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
                text='Lohengrin'
                label='Adventure'
                path='/lohengrin'
              />
            </ul>
            <ul className='cards__items1'>
              <CardItem
                src={`${TheHour}?w=576 576w, ${TheHour}?w=1000 1000w`}
                sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
                text='The Hours'
                label='Luxury'
                path='/thehours'
              />
            </ul>
          </ul>
          <ul className='cards__items'>
            <ul className='cards__items1'>
              <CardItem src={`${fedora}?w=576 576w, ${fedora}?w=1000 1000w`}
               sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
               text='Fedora'
               path='/fedora' />
            </ul>
            <ul className='cards__items1'>
              <CardItem src={`${falstaff}?w=576 576w, ${falstaff}?w=1000 1000w`} 
              sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
              text='Falstaff'
              path='/falstaff' />
            </ul>
          </ul>
        </div>
        <div className='cards__wrapper_1'>
          <ul className='cards__itemscosi'>
            <CardItem src={`${Cosi}?w=576 576w, ${Cosi}?w=1000 1000w`}
            sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
            text='Così Fan Tutte'
            label='Adventure'
            path='/cosi' />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Presentations;