import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { gershwin, lohengrin, Josef_Danhauser_Liszt, tosca, fedora, Cosi, falstaff, TheHour, pays_d_enhaut, diaghi } from '../cards/import';
import './ImageCarousel.css';

const ImageCarousel = () => {
  const images = [
    { id: 1, src1: falstaff, src2: Cosi, alt: 'Image 1' },
    { id: 2, src1: lohengrin, src2: pays_d_enhaut, alt: 'Image 2' },
    { id: 3, src1: tosca, src2: fedora, alt: 'Image 3' },
    { id: 4, src1: gershwin, src2: Josef_Danhauser_Liszt, alt: 'Image 4' },
    { id: 5, src1: diaghi, src2: TheHour, alt: 'Image 5' },
    // Add more images as needed
  ];

  return (
    <div className="carousel-wrapper">
      <Carousel showThumbs={false} showStatus={false} infiniteLoop autoPlay interval={2500}>
        {images.map((image) => (
          <div key={image.id}>
            <div className="image-wrapper">
              <img src={image.src1} alt={image.alt} />
              <img src={image.src2} alt={image.alt} />
              
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;