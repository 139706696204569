import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { lohengrin } from '../../../components/cards/import';

const Lohengrin = () => {
    const title = 'Lohengrin';
    const author = 'Agathe Amzallag';
    const newLocal = `
    <div>
    <h6>Texte adressé par mail et mis à disposition des spectateurs dans les cinémas
    avant la diffusion en direct des opéras du Metropolitan Opera de New York.</h6> 
    <p>Avec Richard Wagner, le langage musical subit une mutation profonde car tout en imposant sa conception progressiste de l’art, il fait évoluer la fonction de la musique, le rôle du compositeur, celui des chanteurs et l’importance de l’orchestre.</p>
    <p>A quinze ans, bouleversé par la découverte de la Neuvième symphonie de Beethoven et par le Faust de Goethe, Richard Wagner prend une décision : il sera compositeur mais aussi philosophe, acteur, metteur en scène et poète car selon lui, la poésie doit féconder la musique. Passionné par le théâtre antique, la tragédie est au cœur de ses préoccupations et tout en écrivant lui-même ses livrets d’opéras, il traitera de sujets issus de la mythologie, des légendes populaires et de l’univers de la magie.</p>
    <p>C’est ainsi que dans les années 1845, après le succès du Vaisseau fantôme et de Tannhäuser, à la cour royale de Saxe, il dirige le célèbre orchestre de la Staatskapelle de Dresde et compose son troisième opéra, Lohengrin.</p>
    <p>Issu de la littérature médiévale germanique du XIIème siècle et de la légende arthurienne du « Chevalier au cygne », Lohengrin occupe une place particulière dans l’œuvre de Wagner car pour la première fois, thème héroïque, ampleur du chant et richesse orchestrale sont étroitement associés alors que de nombreux leitmotivs associés à un thème, un personnage, un objet ou un sentiment apparaissent tout au long de l'œuvre.</p>
    <p>Sur un livret écrit par R. Wagner lui-même, l’histoire de Lohengrin est en trois actes la suivante :</p>
    <p>Au Xème siècle, à Anvers, le chevalier Frederic de Telramund revendique le duché de Brabant et accuse Elsa, fille du duc de Brabant, d’avoir assassiné son frère, le dauphin Gottfried qui a disparu. Pour sa seule défense, la jeune femme appelle à l’aide Lohengrin, le « chevalier au cygne », qui lui est apparu en rêve en lui promettant de la laver de cette accusation infâme. Et le miracle se produit : Lohengrin, noble et mystérieux chevalier, arrive en chair et en os naviguant dans une nacelle escortée par un cygne. Il offre alors de défendre l’honneur d’Elsa et de l’épouser mais à une seule condition : qu’elle ne lui demande jamais ni d’où il vient, ni qui il est. Fidèle à sa promesse, Lohengrin provoque donc en duel le Chevalier Telramund, le défait rapidement et épouse Elsa au milieu d’une grande liesse populaire. Mais, vaincu et humilié, Telramund rumine sa vengeance avec son épouse Ortrud, une sorcière venue du Nord. Tous deux sèment alors le doute et l’inquiétude dans l’esprit de la princesse concernant les origines du mystérieux chevalier. Troublée, déstabilisée, Elsa finit par poser la question interdite et Lohengrin contraint de révéler son identité la quitte. Mais avant de faire ses adieux, il révèle son essence divine et le sens de sa mission sacrée : délivrer le frère disparu de la princesse qu’un sortilège d’Ortrud, véritable coupable, avait ensorcelé et transformé en cygne. A ces mots, Gottfried réapparaît et à la vue de son frère, Elsa s’affaisse sans vie. </p>
    <p>Sur le plan musical, dès le Prélude, avec cette dynamique presque immobile et cet étirement envoûtant du son, le climat de Lohengrin est aussitôt donné, mélange de recueillement et de contemplation avec à l’orchestre des sonorités moelleuses et une sorte de chaleureuse tendresse. </p>
    <p>Présenté à Weimar en 1850, le jeune roi Louis II de Bavière sera tellement influencé par Lohengrin qu’il fera construire le château "Neuschwanstein" (“Nouveau Château du Cygne”) tout en offrant à Wagner, grâce à son mécénat la possibilité de composer librement et de créer le Théâtre de Bayreuth afin qu’il puisse y donner le cycle épique de L'Anneau des Nibelungen.</p>
    <p>Mais avant cela, il est intéressant de souligner que des « fils intérieurs » relient Lohengrin au Vaisseau fantôme car si, dans ces deux opéras, le rôle-titre est un étranger porteur d’un mystérieux destin, Lohengrin -comme le Hollandais- est envoyé au-dessus de l’eau par des forces surnaturelles, sorte de héros mythique certes, mais relié aux hommes par ses émotions. En conclusion, on peut dire que Lohengrin se révèle comme une œuvre romantique au sens le plus élevé du terme embrassant dans une même musique mythe, histoire, conte et tragédie.</p>
    </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={lohengrin} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default Lohengrin