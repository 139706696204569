import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { Beethovenet } from '../../../components/cards/import';

const BeethovenVerdi = () => {
  const title = 'Musique et Politique';
  const author = 'Agathe Amzallag';
  const newLocal = `
  <div>
  <h6>À l’occasion de la réunion annuelle du Lions Club de Suisse,
  conférence avec projections et illustrations musicales sur
  Beethoven, G. Verdi et R. Strauss, trois musiciens très engagés politiquement, qui révèlent et commentent à travers leurs œuvres la vie politique de
  leur temps.</h6>
  <p>Face à l’invasion napoléonienne, Beethoven compose un
  cycle d’œuvres épiques dont les thèmes emprunts d’idéal, de
  passion et de luttes pour la liberté sont uniques dans
  l’Histoire de la musique.</p>
  <p>Face à la domination autrichienne, Verdi, chantre de l’unité
  italienne, compose des opéras qui éveillent le sentiment
  patriotique de toute une génération en lutte pour
  l’unification de de l’Italie.</p>
  <p>En Allemagne, R Strauss, chef d’orchestre et compositeur
  d’une quinzaine d’opéras, cherche à réformer la musique en
  profondeur et tire parti du régime nazi tout en plaçant l’Art
  au- dessus de tout.</p>

  </div>`;
  const content = newLocal;

  return (
    <div>
      <ArticleHeader title={title} image={Beethovenet} />
      <Article title={title} author={author} content={content} />
    </div>
  );
};

export default BeethovenVerdi