import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { gershwin } from '../../../components/cards/import';

const Gershwin = () => {
  const title = 'Gershwin,<br/> une révolution musicale!';
  const author = 'Agathe Amzallag';
  const newLocal = `
  <div>
  <h6>À l’occasion d’une soirée sur le thème « Broadway » dans le cadre du New
  Year Music Festival de Gstaad, conférence « La révolution musicale de Gershwin »
  accompagnée d’une interprétation musicale.</h6>
  <p>Après la première guerre mondiale, New York connait un
  tourbillon culturel et tous les genres musicaux se croisent,
  s’entrechoquent dans un stupéfiant maelstrom ! Novateur,
  réalisant l’union libre du jazz et de la musique orchestrale
  symphonique, Gershwin donne à la musique américaine une
  identité forte, libérée des influences européennes.</p>
  
  </div>`;
  const content = newLocal;

  return (
    <div>
      <ArticleHeader title={<div dangerouslySetInnerHTML={{ __html: title }} />} image={gershwin} />
      <Article title={title} author={author} content={content} />
    </div>
  );
};

export default Gershwin