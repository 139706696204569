import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { tosca } from '../../../components/cards/import';
const Tosca = () => {
    const title = 'Tosca';
    const author = 'Agathe Amzallag';
    const newLocal = `
    <div>
    <h6>Dans le cadre du Festival Menuhin 2023, une conférence centrée sur l’univers
    de G. Puccini, grand maître de la musique italienne et sur son opéra, Tosca.</h6>
  
    <p>En 1895, inspiré par le drame théâtral de Victor Sardou dans
    lequel la tragédienne Sarah Bernhardt incarnait le rôle de
    Tosca, Puccini décide de créer une œuvre dans laquelle art,
    passion, politique, religion, sadisme et complots seront au
    cœur d’un livret ayant l’efficacité d’un scénario de cinéma.
    Poignante histoire d’amour, de jalousie et de mort, Tosca est
    à l’image de la vie mouvementée de Puccini, épicurien,
    sybarite à l&#39;affût de tous les plaisirs et grand amateur de
    femmes.</p>
    
    </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={tosca} />
        <Article title={title} author={author} content={content} />
      </div>
    );
  };

export default Tosca