import React from 'react';
import './About.css';
import { agathe } from '../../components/cards/import';
import EmailOutline  from '@material-ui/icons/Email';

const About = () => {
  return (
    <div className="about_wrapper">
      <div className="about">
        <div className="about__content">
          <h1>
            <span>♬</span> Agathe Amzallag <span>♬</span>
          </h1>
          <p className="about__description">
            Diplômée d’Histoire de la Musique à l’École Normale de Musique de Paris, C. Agathe Amzallag a créé « Musique en
            soirée », un cycle de conférences centrées sur les chapitres marquants de l’Histoire de la Musique accompagnés
            en direct par des illustrations musicales.
          </p>
          <p className="about__description">
            Parallèlement à ces conférences, elle présente concerts et récitals en situant les œuvres dans leur contexte
            historique (Évolution de la société et des instruments, pouvoir en place...) tout en évoquant le moment
            particulier de leur création dans la trajectoire personnelle des compositeurs.
          </p>
          <p className="about__description">
            Agathe Amzallag écrit également des articles liés à l’Histoire de la Musique et collabore à diverses
            publications dont Historia, le Figaro, Art passions et même Le Journal du Pays d’Enhaut. Elle présente
            également chaque mois au cinéma l’Eden les opéras transmis en direct du Metropolitan Opera de New York.
          </p>
          <div className="image-container"> {/* Add a container div */}
            <img
              className='imageagathe'
              alt=''
              src={agathe}
            />
            <label className='label'>
              Conférence Gstaad Août 2023
            </label>
          </div>
          
          <div className="row" id="mobile-adress">
            <div className="icon">
              <div className="col-lg-1 text-center mb-5">
              <div className="icon-wrapper">
                <EmailOutline style={{ color: '#2B50AA', width: 50, height: 50 }} />
              </div>
                <p className="info">
                  <a href="mailto:agathe.amzallag@gmail.com">agathe.amzallag@gmail.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;