import React from 'react';
import './Article.css';

const Article = ({ author, content}) => {
  return (
    <div className="article-container">
      <h2 className="author">{author}</h2>
      <div className="article-content" dangerouslySetInnerHTML={{ __html: content}} />
    </div>
  );
};

export default Article;