import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const navLinks = [
    { id: 'accueil', title: 'Accueil ', path: '/' },
    { id: 'écrits', title: 'Articles ', path: '/écrits' },   
    { id: 'présentation_d_opéra', title: 'Présentations d\'opéras ', path: '/présentation_d_opéra' },
    { id: 'conférences', title: 'Conférences', path: '/conférences ' },
    { id: 'à_propos', title: 'À propos', path: '/à_propos' },
  ];

  const handleNavLinkClick = () => {
    setToggleMenu(false);
  };

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_container">
          {navLinks.map((link, index) => (
            <li key={link.id}>
              <Link to={link.path} onClick={handleNavLinkClick}>
                <span>{link.title}</span>
                
              </Link>
              {index < navLinks.length - 1 && <span>  -</span>}
            </li>
          ))}
        </div>
      </div>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine color="#00241B" size={27} onClick={() => setToggleMenu(false)} />
        ) : (
          <RiMenu3Line color="#00241B" size={27} onClick={() => setToggleMenu(true)} />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              {navLinks.map((link) => (
                <li key={link.id}>
                  <Link to={link.path} onClick={handleNavLinkClick}>
                    <span>{link.title}</span>
                  </Link>
                </li>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
