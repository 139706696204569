import React from 'react';
import '../../components/cards/Cards.css';
import CardItem from '../../components/cards/CardItem';
import {
  Beethoven,
  pays_d_enhaut,
  Programmes,
  Josef_Danhauser_Liszt, 
  diaghi
} from '../../components/cards/import';
import './Ecrits.css';

function Ecrits() {
  return (
    <div className='cards ecrits'>
      <h1>
        {/* <span>∼</span> */} Articles  {/* <span>∼</span> */}
      </h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <ul className='cards__items1'>
            <CardItem
              src={`${Programmes}`}
              sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
              text='Les Ballets russes embrasent Paris'
              label='Adventure'
              path='/lesballets'
            />
            </ul>
            <ul className='cards__items1'>
            <CardItem
              src={`${Beethoven}`}
              sizes="(min-width: 680px) 500px, (min-width: 400px) 288px, 248px"
              text={[
                'Goya - Beethoven',
                <br />,
                'Deux génies à l\'unisson'
              ]}
              path='/deuxgenies'
            />
            </ul>
          </ul>
          <ul className='cards__items'>
            <ul className='cards__items1'>
            <CardItem
              src={`${pays_d_enhaut}`}
              sizes="(min-width: 680px) 500px, (min-width: 400px) 288px, 248px"
              text='Les arbres font «chanter» la musique'
              path='/paysdenhaut'
            />

            </ul>
            <ul className='cards__items1'>
            <CardItem
              src={`${Josef_Danhauser_Liszt}`}
              sizes="(min-width: 680px) 500px, (min-width: 400px) 288px, 248px"
              text='Les salons de musiques sont tendance'
              path='/lessalons'
            />
            </ul>
          </ul>
        </div>
        <div className='cards__wrapper_1'>
          <ul className='cards__itemscosi'>
            <CardItem src={`${diaghi}?w=576 576w, ${diaghi}?w=1000 1000w`}
            sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
            text='Diaghilev intime'
            path='/diaghi' />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Ecrits;