import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { Josef_Danhauser_Liszt } from '../../../components/cards/import';

const LesSalonsMusique = () => {
    const title = 'Les salons de musiques sont tendance';
    const author = 'Agathe Amzallag';
    const image = Josef_Danhauser_Liszt;
    const newLocal = `
    <div>
    <h6>Pour le Magazine Historia (Novembre 2009)</h6>
    <p>Lieu propice à l'audition de nouvelles œuvres, ces cercles offrent l'opportunité aux compositeurs d'asseoir leur renommée. Chopin, Lalo, Massenet, Liszt, Gounod sont les stars de l'époque.</p>
    <p>Qui dit salon de musique pense pratique mondaine, réjouissances musicales, réunions pour amateurs ou initiés. Et tels sont bien au XVIIIe siècle ces cercles privés, objets de plaisir, de convoitise et forme privilégiée de communication musicale.</p>
    <p>Mais, au fil du temps, avec l'évolution de la société et le renouvellement des goûts, ils prennent une dimension nouvelle et s'inscrivent dans le mouvement de l'Histoire, notamment au XIXe siècle où, lieux de découverte, ils connaissent un véritable apogée.</p>
    <p>À l'époque, en effet, ces salons sont au cœur de la vie parisienne, des Tuileries au faubourg Saint-Germain en passant par les nouveaux quartiers de la Chaussée-d'Antin. Jules de Castellane raconte avoir « fait » treize salons dans la même soirée, un véritable tour de force! Cependant, certaines maisons se distinguent. Celle du prince de la Moskowa par exemple, fondateur du Jockey Club mais aussi de la Société des concerts de musique religieuse et classique, ou encore celle d'Édouard Rodrigues qui dirige son propre orchestre, la fine fleur des cordes françaises.</p>
    <p>Connue dans tout Paris pour sa liaison avec Franz Liszt. la comtesse Marie d'Agoult, quant à elle, recoit deux fois par semaine. Elle note dans ses Mémoires: « Le salon est l'am-bition suprême de la Parisienne, la consolation de sa maturité, la gloire de sa vieillesse. » Chez elle, Sainte-Beuve, Lamartine et Théophile Gautier côtoient Vigny ou Balzac mais la musique est toujours au centre de la conversation. Et on en fait beaucoup!</p>
    <p>Rossini joue les airs de son Barbier Chopin interprète ses composition et, selon son humeur, Liszt se livre à ses célèbres improvisations. Un soir, entendant le jeu fracassant du hongrois, l'écrivain Heinrich Hein s'exclame: «Liszt est l'Attila du piano!»</p>
    <p>À peu près au même moment de l'autre côté de la Seine, au 29 rue de Courcelles, la princesse Mathilde, cousine de Napoléon III, règne elle aussi avec intelligence son salon qui est une sorte de «ministere des Beaux-Arts ». Attirant causeurs brillants et jeunes gens à promovoir, on forge là des conceptions d'avenir tout en considérant que l'art peut rêgénérer et compenser les failles de la politique. Le lundi est « jour de musique », mais la maîtresse de maison ne s'y intéresse guère; elle apprécie peu Wagner quant à Gounod, personnalité «bonhomme », sa qualité d'artiste la laisse assez indifférente. Quoi qu'il en soit, de nombreux musiciens fréquentent son salon parmi lesquels les compositeurs Fromenthal, Halévy ou encore Auber sans oublier Saint-Saëns qui compose pour elle sa Sérénade pour piano, orgue, violon et violoncelle. </p>
    <img src=${image} />
    <label><span style="font-style: italic; text-decoration: underline; text-underline-offset: 4px; text-decoration-skip-ink: auto; font-weight: bold;" >Concert Privé</span></label>
    <label>Les écrivains se pressent aussi à ces soirées comme sur le tableau de Danhauser, Liszt au piano, avec de gauche à droite: Dumas père, Hugo, George Sand, et les musiciens Paganini, Rossini, Franz Liszt, Marie d'Argoult et Beethoven en buste. Nationalgalerie de Berline</label>
    <p>Au cours de ces soirées plus intellectuelles que mondaines, on discute ferme et parfois la princesse laisse éclater sa jalousie lorsqu'elle comprend qu'en sortant de chez elle, ses invités vont se rafraîchir dans un bal populaire ou pire, dans d'autres salons! Avec, en première place, celui de la cantatrice Pauline Viardot.</p>
    <p>Tous les jeudis soirs, en effet, au 48 de la rue de Douai, Pauline Viar-dot recoit les musiciens les plus éminents et présente les œuvres encore inconnues de compositeurs promet-teurs. Massenet et Saint-Saëns disent qu'elle a lancé leurs carrières, de même Gounod qui la considère comme sa «marraine » depuis qu'elle l'a aidé a produire Sapho à l'Opéra. Et là, que de découvertes! Le Carnaval des animaux donné en première audition par Saint-Saëns au cours d'une soirée en l'honneur de Liszt, le Concerto pour piano op. 16de Grieg et tant d'autres.</p>
    <p>Saint-Saëns reçoit les lundis, Henri Duparc les mardis conscients de l'importance des salons, les musiciens se mettent de la partie et ouvrent eux aussi leur maison. Parmi celles-ci, la villa de Saint-Cloud où Charles Gounod fait entendre pour la première fois en 1887.</p>
    <p>Le Roi d'Ys d'Édouard Lalo tandis que Saint-Saens lance ses fameux « lun-dis». Dans une lettre à Gabriel Fauré, il note: «Nous avons écouté hier soir à la maison un grand charivari de ma composition [sa Sérénade pour piano, orgue, alto et violon op.15] qui a eu un succès épatant! » Même état d'esprit et autres «premières » chez Lalo et, plus tard, aux «mardis » d'Henri Duparc.</p>
    <p>Au tournant du siècle, avec le développement des concerts et récitals publics, les salons de musique, lieux de découverte, d'influence et de pouvoir, font donc office de tremplins. Et, fait intéressant, ils marquent l'Histoire car, antichambres à toute carrière musicale, ils stimulent la création et la diffusion de la musique, consacrent les réputations tout en créant de véritables réseaux. Points de convergence entre journalistes, personnalités mondaines, organisateurs de concerts, impresarios et musiciens, ils favorisent certes les échanges et les collaborations mais, plus encore, ils révèlent œuvres et talents. </p>
    
    </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={Josef_Danhauser_Liszt} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default LesSalonsMusique