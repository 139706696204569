import React from 'react';
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { Cosi } from '../../../components/cards/import';

const CosiArticle = () => {
  const title = 'Così Fan Tutte';
  const author = 'Agathe Amzallag';
  const newLocal = `
  <div>
  <h6>Texte de présentation inséré dans le programme de Cosi Fan Tutte lors de sa
  version en concert donnée au Théâtre de Beaulieu à Lausanne.</h6>
  <p>Après les Noces de Figaro et Don Juan, c'est peu de temps avant sa mort que Mozart compose son troisième opéra, Così fan tutte.</p>
  <p>Commandé par l'Empereur Joseph II, il imagine en effet avec son librettiste, Lorenzo Da Ponte, une œuvre originale dont le thème s'inspire d'une histoire qui, à l'époque, avait amusé toute la ville de Vienne : Deux officiers avaient échangé leurs femmes !</p>
  <p>En un mois, livret et partition achevés, la première représentation a lieu en janvier 1790 au Burgtheater de Vienne. Bien accueilli, Cosi fan tutte fût un succès mais de courte durée hélas, car l'Empereur Joseph II décédant quelques jours plus tard, le deuil de cour suspendit toutes les représentations.</p>
  <p>Opéra Buffa en deux actes, œuvre de chambre à six personnages, Cosi fan tutte, est une sorte de méditation douce et amère sur la fidélité des femmes avec en toile de fond travestissements, faux adieux et tromperies. Psychodrame au sens moderne du terme, il s'agit là d'une farce et d'une fable philosophique sur l'amour, le titre à soi seul résumant le propos : Così fan tutte (« Ainsi font-elles toutes ») autrement dit, toutes les femmes trompent les hommes !</p>
  <p>L'intrigue se déroule en effet au XVIIIème siècle dans la baie de Naples où, profondément convaincu de l'infidélité des femmes, le cynique Don Alfonso provoque ses jeunes amis, Ferrando et Guglielmo, en mettant en doute la constance de leurs fiancées, les sœurs Dorabella et Fiordiligi. Pour prouver ce quil avance, Alfonso leur propose d'annoncer leur départ à la guerre, puis de revenir masqués sous les traits de deux soldats albanais prêts à tout pour séduire les fiancées esseulées. Dans un premier temps, les deux sœurs les repoussent vertueusement mais se laissant bientôt séduire par ces nouveaux soupirants très entreprenants, ces derniers voient avec effroi leurs maîtresses les trahir. « Ainsi font-elles toutes ! »</p>
  <p>(« Così fan tutte ») triomphe Don Alfonso, ravi d'avoir prouvé sa théorie ! Mais une fois la supercherie découverte, les deux couples se reformeront malgré tout.</p>
  <p>Oscillant entre libertinage, tendresse, brûlure amoureuse et résignation, l'œuvre est portée par une musique simple, transparente, radieuse, car Mozart s'est pris au jeu de la comédie napolitaine.</p>
  <p>Ses personnages sont légers, ils échangent leurs fiancées et le défi rejoint en apparence la morale du divertissement. Pourtant, malgré le caractère ironique et parodique du livret, la musique nous fait comprendre à diverses reprises l'évolution subtile des sentiments non seulement des deux jeunes-filles mais celle aussi de leurs fiancés Ferrando et Guglielmo, qui à certains moments se laissent émouvoir et séduire au point de renoncer à leur stratagème. Ainsi, nous dit Mozart, au jeu de l'amour nul n'est épargné ! Hommes et femmes se retrouvent à égalité dans la joie comme dans la tourmente et tout en prenant la souffrance et les élans amoureux de ses personnages très au sérieux, il crée un univers sonore singulier dont la beauté tragique et les mots sont presque impuissants à rendre compte.</p>
  </div>`;
  const content = newLocal;

  return (
    <div>
      <ArticleHeader title={title} image={Cosi} />
      <Article title={title} author={author} content={content} />
    </div>
  );
};

export default CosiArticle;