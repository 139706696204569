import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { pays_d_enhaut } from '../../../components/cards/import';

const PaysDenhautArticle = () => {
    const title = 'Les arbres font chanter la musique';
    const author = 'Agathe Amzallag';
    const newLocal = `
  <div>
  <h6>Pour le Journal du Pays d’En Haut (Suisse)</h6>
  <p>Source de mystères et de fascination, monde grouillant de forces obscures où règne la loi du plus fort, la forêt sonne ,"chante" résonne... Et de tous temps les poètes se sont mis à l'écoute de ses murmures, de ses cris, de ses bruissements alors même que le bois de ses arbres donnait naissance à nos instruments et dès le Haut Moyen-Age à l'art musical occidental dans son ensemble! C'est bien dans cet esprit d'ailleurs que le Festival du Bois qui chante présidé par Patrick Scherrer a organisé sur le thème de «La forêt et la musique» une magnifique ballade didactique menée par Frédéric Blum et Pierre Yer-sin, forestiers expérimentés en charge des forêts du Pays-d'Enhaut. Curieux et enthousiaste, un groupe de quatrevingt participants a donc découvert dans la lumière automnale d'un matin ensoleillé la forêt des Arses avec ses sapins blancs et ses épicéas, arbres séculaires dont le «bois de résonance» a depuis toujours présidé à la fabrication des instruments de musique.</p>
  <p>Et aussitôt la question s'est posée: A quoi reconnaît-on un «bois de résonance»?</p>
  <p>A la rondeur et à la droiture de son tronc exempt de traces de branches, de blessures et de pourriture, explique Frédéric Blum, mais aussi à l'harmonie de son bois, au diamètre de sa couronne avec des branches très étroites qui le protègent de la neige. Une fois coupé, on va analyser le bois qui doit avoir crû sans à-coup et être constitué de cernes réguliers très serrés, preuve de sa croissance lente, chaque cerne correspondant à une année de pousse.</p>
  <p>Ces critères réunis, encore faut-il choisir l'arbre à abattre, ce dernier étant généralement âgé de 180 à 250 ans. Pour ce faire, on va tout d'abord, selon une technique ancestrale, le frapper, «l'écouter» avant même d'en récolter la précieuse matière. Une fois à terre, écorce, séché, découpé, ce bois d'une qualité exceptionnelle sera alors acheté par un luthier qui lui fera subir différentes transformations dont l'une des principales étapes sera la fabrication de la table d'harmonie. De quoi s'agit-il?</p>
  <p>Concrètement de deux planchettes jumelles parfaitement symétriques provenant du même morceau de bois découpé radialement dans le tronc. Puis, une fois séparées en deux, elles seront collées l'une au-dessus de l'autre sur leur partie externe. Ainsi se retrouveront au centre de l'instrument les cernes de l'arbre les plus récents -moment de sa vie où il était jeune et grossissait vite- ceux situés sur les bords étant souvent plus larges et espacés.</p>
  <p>On. compte généralement un minimum de 50 cernes pour une moitié de violon, voire 100 à 200 cernes pour une largeur de 12 cms.</p>
  <p>Par la suite, pour que cet assemblage résonne et se transforme en instrument, on ajoutera des cordes qui, au travers d'un chevalet, transmettront une énergie mécanique, une vibration, elle même génératrice de son.</p>
  <p>Ainsi naîtront à cordes frottées, pincées ou frappées, violon, guitare, piano, harpe ou autres instruments baroques tels le luth ou la viole.</p>
  <p>Il est d'ailleurs intéressant de souligner que si les célèbres luthiers italiens de la ville de Cremone, Andrea Amati et Stradivari, ont dès le XVIIe siècle donné aux cordes leurs lettres de noblesse, aujourd'hui à quelques détails près la fabrication de ces instruments reste inchangée. Témoin, le violon qui, depuis quatre siècles, se compose toujours de 71 pièces collées.</p>
  <p>Epicéas, «bois de résonance», instruments..</p>
  <p>Au-delà des matières et de leurs produits, il serait injuste de passer sous silence la présence et le rôle determinant joués par la forêt dans l'histoire de la musique. Car c'est un fait, la forêt «habite» l'Opéra! Lieu de bonheur, d'amour, de protection ou de me-naces, pulsions obscures et forces infernales s'y déchaînent alors même qu'apparaissent entités lumineuses et surnaturelles. Pour s'en convaincre, il suffit d'écouter certains passages de la Flûte enchantée de Mozart, du Siegfried de Wagner ou encore du Pélleas et Mélisande de Debussy. Pour les compositeurs, source éternelle d'inspiration, la forêt est un thème mythique, un personage à part entière qui, plus qu'un décor, participle à l'action théâtrale. Et que de sublimes transpositions musicales du monde animal on peut y en-tendre! Du fourmillement des oiseaux dans le Rinaldo de Haendel aux Aventures de La petite renarde rusée relatées avec tant d'originalité par le compositeur tchèque, Janacek... la Nature s'empare de notre oreille et lui offre l'enchantement de ses plus belles «couleurs»!</p>
  <p>Toutefois, au terme de cet article, le mélomane se doit de faire silence et de revenir un instant dans un dernier hommage à la source même de son plaisir car, sans la force tellurique des arbres et l'harmonie vibrante des forêts, la planète ne «chanterait» pas.</p>
  </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={pays_d_enhaut} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default PaysDenhautArticle