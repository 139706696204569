import React from 'react';
import './ArticleHeader.css';

const ArticleHeader = ({ title, image }) => {
  return (
    <div className="article-header" style={{ backgroundImage: `url(${image})` }}>
      <div className="overlay"></div>
      <h1>{title}</h1>
    </div>
  );
};

export default ArticleHeader;