import React from 'react';
import '../../components/cards/Cards.css';
import CardItem from '../../components/cards/CardItem';
import { gershwin, Beethovenet, tosca, lamour, boris } from '../../components/cards/import';
import './Conference.css';

function Conferences() {
  return (
    <div className='cards conference'>
      <h1>
    {/*<span>∼</span> */}Conférences {/*<span>∼</span>*/}
        
      </h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <ul className='cards__items1'>
              <CardItem
              src={`${boris}?w=576 576w, ${boris}?w=1000 1000w`}
              sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
              text={['Boris Godounov',
              <br />,
              'Pouvoir, crime et châtiment'
              ]}
              path='/boris'
              />
            </ul>
            <ul className='cards__items1'>
              <CardItem
                src={`${Beethovenet}?w=576 576w, ${Beethovenet}?w=1000 1000w`}
                sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
                text='Musique et Politique'
                label='Luxury'
                path='/beethovenverdi'
              />
            </ul>
          </ul>
          <ul className='cards__items'>
            <ul className='cards__items1'>
              <CardItem src={`${tosca}?w=576 576w, ${tosca}?w=1000 1000w`}
              sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
              text='Tosca'
              path='/tosca' />
            </ul>
            <ul className='cards__items1'>
              <CardItem src={`${lamour}?w=576 576w, ${lamour}?w=1000 1000w`}
              sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
              text="L'Amour et ses détours"
              path='/lamour' />
            </ul>
          </ul>
        </div>
        <div className='cards__wrapper_1'>
          <ul className='cards__itemscosi'>
          <CardItem
          src={`${gershwin}?w=576 576w, ${gershwin}?w=1000 1000w`}
          sizes='(max-width: 576px) 576px, (max-width: 1000px) 1000px, 1600px'
          text='Gershwin, une révolution musicale!'
          label='Adventure'
          path='/gershwin'
          />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Conferences;
