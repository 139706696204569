import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';  
import './index.css';
import { BrowserRouter } from 'react-router-dom';
// Create a link element
const link = document.createElement('link');
link.rel = 'preload';
link.href = 'fonts/Tangerine/Tangerine-Bold.ttf';
link.as = 'font';
link.crossOrigin = true;

// Append the link element to the document's head
document.head.appendChild(link);

ReactDOM.render(
 <BrowserRouter>
    <App />
 </BrowserRouter>,
 document.getElementById('root')
 
);
document.querySelector('meta[name="viewport"]').setAttribute("content", "width=device-width, initial-scale=1.0");