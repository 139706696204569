import React from 'react';

import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { diaghi } from '../../../components/cards/import';
  
const Dhiaghilev = () => {
  const title = 'Diaghilev intime';
  const author = 'Agathe Amzallag';
  const newLocal = `
  <div>
  <h6>Pour Le Figaro – Rubrique La Russie d’aujourd’hui</h6>
  <p>Né dans l'Oural le 31 mars 1872 avec une si grosse tête que toute sa vie il commandera ses chapeaux sur mesure, Serge Diaghilev, alias «Chinchilla» - surnom dû à sa mèche blanche - fut un Bélier d'envergure !</p>
  <p>Critique d’art, impresario, fondateur en 1909 des Ballets russes, l’une des plus formidables aventures artistiques du XXème siècle, il avait pour devise « l’art pour l'art ». Dès lors, bousculant conventions et formalisme, planifiant ses entreprises comme un général d’armée, il révèle talents et vocations et par la fusion de toutes les expressions artistiques crée un « spectacle total ». Émerveillé, choqué, séduit, amusé… pendant vingt ans, le public sera tenu en haleine ! </p>
  <p>«C'était un artiste exceptionnel », raconte son secrétaire, Boris Kochno. « Il ne créait par personne interposée. On disait qu'il descendait de Pierre le Grand… par la cuisse gauche, mais en un sens il était Pierre le Grand lui-même, surtout dans son acception dictatoriale, autocratique ! Car, avant même d’être un directeur, Dhiaghilev était un dictateur ». (1)</p>
  <p> Dominateur, souvent arrogant, détestant la routine, il avait une personnalité exigeante et sévère se doublant d’une « volonté artistique fédératrice » « Souvent les idées elles-mêmes n'appartenaient pas à Diachilev », note le peintre Alexandre Benois, ami et collaborateur de la première heure. « Il était plutôt pauvre en invention, mais il savait attraper au vol tout ce qui surgissait de viable dans la tête de ses amis et à partir de ce moment, ces projets devenaient les siens ». (2) </p>
  <p>Sa nature, telle qu'elle allait s'exprimer dans les années à venir, Diaghilev la résume lui-même dans une lettre à sa belle-mère : « Je suis : premièrement, un charlatan, d'ailleurs plein de brio ; deuxièmement, un grand charmeur ; troisièmement, un insolent ; quatrièmement, un homme possédant beaucoup de logique et peu de scrupules ; cinquièmement, un être affligé semble-t-il d’une absence totale de talent. D’ailleurs, je crois avoir trouvé ma véritable vocation : le mécénat. Pour cela, j’ai tout ce qu’il faut, sauf l’argent. Mais ça viendra ! ». </p>
  <p>Organisateur ambitieux, promoteur audacieux, Diaghilev entretenait avec l’argent un rapport peu scrupuleux qui fit de lui un virtuose des emprunts. Acrobate sans filet ? Certes, mais son talent relationnel et son pouvoir de conviction lui éviteront le pire. « C’était l’ami le plus charmant », souligne Coco Chanel. « Je l’aimais dans sa hâte à vivre, dans ses passions, dans ses guenilles, si loin de sa légende fastueuse… Têtu, généreux, avare, puis gaspilleur, ne sachant jamais d’avance ce qu’il va faire, achetant pour rien des toiles sans prix, les donnant, se les laissant voler, il traverse l’Europe en mécène sans le sou, son pantalon tenu par des épingles doubles…» (3) Son domicile ? L’hôtel, avec deux valises et deux nécessaires de toilette, cadeaux de Nijinsky et de Massine. </p>
  <p>« Il ne souhaitait rien posséder jusqu’au moment où il a commencé à collectionner des livres russes anciens », raconte B.Kochno. En fait, il avait pour tout vêtement un habit, un smoking et le complet qu’il portait sur lui. Et quand celui-ci n'était plus présentable, il en
  commandait un nouveau. On faisait un paquet de l'ancien qu’on jetait enveloppé dans un papier journal. Je me souviens même qu’un jour à venise, on le lança dans le Grand Canal… »
  </p>
  <p>Dans sa pelisse doublée de fourrure sibérienne et sanglée de brandebourgs, flanqué d'une canne dont il n’avait besoin que pour « toucher du bois » ou corriger un danseur Diaghilev vivait au sein de sa troupe des relations passionnelles qui frisaient parfois la caricature. Un jour, par exemple, alors que Rodin, lui aussi attiré par les charmes masculins, avait dessiné Nijinsky venu poser dans l’atelier Maillol, Dhiaghilev fit irruption, fou de rage, et « les deux hommes se brouillèrent à cause du danseur » (4)</p>
  <p>Somptuosité des décors, créativité des costumes, chorégraphie avant-gardiste, musiques inédites, danseurs légendaires… Pour le public parisien, les Ballets russes étaient un choc à la sois artistique et culturel ! Et les réactions furent parfois explosives… Témoin, la première en 1913 du « Sacre du printemps » au Théâtre des Champs Elyssées. La salle était comble et il y avait ce soir-là tous « les matériaux d’un scandale », souligne Jean Cocteau. Diaghilev, Bon tacticien et fin limier, avait distribué aux jeunes de l’avant-garde venus applaudir Stravinsky et Nijinsky des billets de promenade qui mettaient ainsi les élégants à leur portée. Et le « scandale » dépassa ce que l’on pouvait attendre : hurlement, injures, hululements, gifles, coups…l’assistance était déchaînée ! </p>
  <p>« Après la représentation », raconte Stravinsky, « nous étions excités, furieux, dégoutés et… heureux. Le seul commentaire de Diaghilev fut : « Exactement ce que je voulais ». Il n’y avait aucun doute : il paraissait content. Personne n’était plus prompt que lui à saisir la valeur publicitaire d’une situation et ce qui était arrivé ne pouvait qu’être excellent. » (5).</p>
  <p>Aujourd’hui, cent ans après leur création, les Ballets russes restent une référence et une source d’inspiration qui ne cessent d’enflammer le public. Pour Diaghilev, le pari est gagné. Pourtant, s’effaçant derrière son œuvre, il osera dire, peu de temps avant sa mort à Venise en 1929 : « Je voudrais vraiment que de moi-même il ne restât rien. Car si par malheur on s’avisait un jour de faire un « Musée Diaghilev », qu’y pourrait-on exposer ? Mon pince-nez et mes charentaises… ». (6)</p>
  
  <div class="h6-group">
  <h7>(1)	Interview de B.Kochno parue dans Danser n°21, mars 1985.</h7>
  <h7>(2)	 Souvenirs, d’Alexandre Benois</h7>
  <h7>(3)	Propos rapportés par Paul Morand dans son Journal inutile</h7>
  <h7>(4)	Brassaï, les artistes de ma vie Paris, Denoël 1982</h7>
  <h7>(5)	Stravinsky, Conversations, op.cit p.46-47</h7>
  <h7>(6)	Interview de B.Kochno parue dans Danser n°21 mars 1985</h7>
  </div>
  </div>`;
  const content = newLocal;

  return (
    <div>
      <ArticleHeader title={title} image={diaghi} />
      <Article title={title} author={author} content={content} />
    </div>
  );
};

export default Dhiaghilev;