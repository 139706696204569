import React from 'react'
import Article from '../../../components/Article/article';
import ArticleHeader  from '../../../components/Article/ArticleHeader';
import { falstaff } from '../../../components/cards/import';

const Falstaff = () => {
    const title = 'Falstaff';
    const author = 'Agathe Amzallag';
    const newLocal = `
    <div>
    <h6>Texte adressé par mail et mis à disposition des spectateurs dans les cinémas
    avant la diffusion en direct des opéras du Metropolitan Opera de New York.</h6>
    <p>En 1889, après cinquante ans de créations et vingt-quatre tragédies à succès, Verdi signe un chef d'œuvre d'un genre nouveau et compose une comédie pétillante de gaité et de verve comique, Falstaff. Mais à cette annonce, quelle ne fut la surprise du monde musical !</p>
    <p>En effet, peu nombreux étaient à l'époque ceux qui croyaient le vieux Maître capable de s'attaquer à un opéra-bouffe et parmi ces derniers Rossini qui, malgré son admiration, pensait Verdi incapable de gagner ce pari ! Mais heureux de vivre et faisant preuve d'une éclatante jeunesse, Verdi leur répondit : Après avoir sans trêve massacré tant de héros et héroïnes, ¡'ai finalement le droit de rire un peu...»</p>
    <p>Créé à Milan sur le livret de Boito, Falstaff est principalement tiré de l'œuvre de Shakespeare, Les Joyeuses Commères de Windsor, et repose sur des situations héritées de la Commedia dell'arte. « Falstaff est un fripon qui fait toutes les vilenies possibles mais de manière drôle, dira Verdi et Il faut avoir la sagesse du grand âge pour pouvoir donner vie à ce personnage ».</p>
    <p>L'action en tous les cas se passe à Windsor au début du XVème siècle et l'argument en trois actes est le suivant :</p>
    <p>Sir John Falstaff, dit aussi « il Pancione » (Le ventru), doit trouver rapidement de quoi régler ses dettes à l'auberge de la Jarretière où il mène une vie de sybarite sans en avoir les moyens.
    Cherchant à séduire conjointement deux riches bourgeoises, Alice Ford et Meg Page, il adresse à toutes deux une lettre d'amour aussi ridicule qu'enflammée. Victimes potentielles…. peut-être... mais femmes rusées assurément, elles ne tardent pas à découvrir la supercherie et décident de se venger avec malice de cette bouffonnerie. C'est ainsi que de faux-semblants en déguisements, le pauvre Falstaff va devenir le jouet des deux commères, habiles à lui tendre un piège tout en contribuant à l'union de deux jeunes amoureux qu'un père autoritaire cherche à séparer.
    </p>
    <p>Quoiqu'il en soit, après sa mésaventure, trempé et transi de froid, Falstaff tout en se consolant avec un hanap de vin s'impose dans son célèbre monologue sur l'honneur (au début de l'acte III) comme un personnage d'une dimension beaucoup plus profonde que celle créée par Shakespeare car il conclura avec humour: Le monde entier est une farce et l'homme est né bouffon !</p>
    <p>La première eut lieu en Février 1893, une semaine après l'immense succès remporté par
    Manon L'Escaut, œuvre du jeune et prometteur Giacomo Puccini, dont le romantisme fougueux coïncidait avec le chant du cygne d'un vieux maître en pleine possession de ses moyens. Le triomphe fut unanime ! Tous, spectateurs, critiques, musiciens... considérèrent Falstaff comme un chef-d'œuvre, soulignant sa modernité, l'économie dans le traitement des voix, la qualité de l'orchestration et les mélodies qui explosent en mille éclats d'un rire énorme et éblouissant. Quant à la voix humaine traitée ici comme un instrument et la musique, elle n'est que théâtre; elle suit le texte, rit avec lui, accentue les petits drames et les tensions, expose l'énormité du chevalier truculent, irradie de tendresse envers les deux amoureux et ensorcelle dans la féerie du dernier acte. Célèbre dans le monde entier, Falstaff, sera le dernier opéra de Verdi et en prenant congé à quatre-vingts ans de la scène lyrique, il nous adresse là un testament chargé de drôlerie, de malice et de merveilleux.
    </p>
    </div>`;
    const content = newLocal;
  
    return (
      <div>
        <ArticleHeader title={title} image={falstaff} />
        <Article title={title} author={author} content={content} />
      </div>
    );
}

export default Falstaff